<template>
  <main class="h-screen">
    <base-picture
      v-if="responsiveMedia.images"
      v-bind="responsiveMedia.images"
      fit="cover"
      class="full"
      :props-img="{ class: 'h-full' }"
    />
    <base-video
      v-else-if="responsiveMedia.video"
      v-bind="video"
      ref="videoRef"
      autoplay
      loop
      class="full cover"
    />
  </main>
</template>

<script lang="ts" setup>
import type { HeroContent } from '#types/components/cms/hero'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const route = useRoute()
const { $gtm, $sendExtraMonetateEvents } = useNuxtApp()
const { getPage, getMedia, getVideo, getSeoMetadata } = useCms()

const page = await getPage()
const media = (page?.sections?.main?.items?.[0] as HeroContent)?.media
const responsiveMedia = getMedia(media) || {}
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

if (page) {
  const { metadata, link } = getSeoMetadata(page)
  useSeoMeta(metadata)
  useHead(() => ({ link }))
}

// defines markup via unhead-schema for search functionality on homepage
// https://unhead-schema-org.harlanzw.com/guide/getting-started/how-it-works
useSchemaOrg([
  defineWebSite({
    potentialAction: [
      defineSearchAction({
        target: '/search?q={search_term_string}'
      })
    ]
  })
])

onMounted(async () => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }

  $gtm.push('page.onLoadPageData', route, {
    pageTitle: await getPageTitle(),
    pageCategory: 'Home',
    pageName: 'Home'
  })
  $sendExtraMonetateEvents()
  $gtm.push('user.onLoadUserData', await getUserEventData())
  $gtm.push('page.onRouterChange', route)
})
</script>
